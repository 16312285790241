export function getDCList(params) {
  return this.$api.get('dc/list', params)
    .then(({ data }) => {
      return data
    })
    .catch(() => null)
}

export default {
  methods: {
    getDcs(params) {
      return this.$api.get('dcs', params)
        .then(({ data }) => {
          return data
        })
        .catch(() => null)
    },

    getBlockedDcs(params) {
      return this.$api.get('dcs/blocked', params)
        .then(({ data }) => {
          return data
        })
        .catch(() => null)
    },

    getDc(id) {
      return this.$api.get('dc/' + id)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    updateDc(id, params) {
      return this.$api.post('dc/' + id, params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    changeDcStatus(id) {
      return this.$api.post('dc/' + id + '/change_status')
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}

