<template>
  <v-form
    ref="form"
    v-model="valid"
    class="rcc-distribution-center__form"
    @submit.prevent="handleFormSubmit"
  >
    <div class="rcc-distribution-center__form-body">
      <rcc-text-input label="Номер с портала" is-disabled width="200px" v-model="form.id_portal" />
      <rcc-text-input label="Название" width="30%" is-disabled v-model="form.name" />
      <rcc-select label="Регион" width="30%" v-model="form.region_id" :items="regionsOptions" />
      <rcc-select label="Сеть" width="30%" v-model="form.network_id" :items="networksOptions" />

      <section class="rcc-distribution-center__form-section">
        <h2>Бронирование</h2>

        <div class="rcc-distribution-center__booking-section">
          <rcc-number-input
            class="rcc-distribution-center__number-input"
            label="min кол-во паллет"
            :min="1"
            width="30%"
            v-model="form.intermediate_booking_min_pallet"
          />

          <rcc-number-input
            class="rcc-distribution-center__number-input"
            label="Оптимальное кол-во паллет"
            width="30%"
            v-model="form.consolidation_booking_soft_limit_pallet"
            :rules="[bookingSoftLimitValidation]"
          />

          <rcc-number-input
            class="rcc-distribution-center__number-input"
            label="max кол-во паллет"
            :min="1"
            width="30%"
            v-model="form.intermediate_booking_max_pallet"
          />
        </div>
      </section>

      <!-- <section class="rcc-distribution-center__form-section">
        <h2>Консолидационное бронирование</h2>

        <div class="rcc-distribution-center__booking-section">
          <rcc-number-input
            class="rcc-distribution-center__number-input"
            label="min кол-во паллет"
            :min="1"
            v-model="form.consolidation_booking_min_pallet"
          />

          <rcc-number-input
            class="rcc-distribution-center__number-input"
            label="max кол-во паллет"
            :min="1"
            v-model="form.consolidation_booking_max_pallet"
          />
        </div>
      </section> -->
    </div>

    <rcc-confirm-dialog
      :is-show.sync="isShowConfirmDialog"
      title="Заблокировать РЦ?"
      text="Данное действие приведет к блокировке РЦ. Вы уверены?"
      :confirmCallback="dcChangeStatus"
    />

    <rcc-footer-buttons
      :is-submit="isSubmit"
      :waningText="warningButtonText"
      :is-warning-button-disabled="isBlockedInX5"
      @warning-click="handleBlockDcClick"
      @cancel-click="$router.push({ name: 'distribution-centers' })"
    />
  </v-form>
</template>

<script>
import DistributionCenterApi from '@/api/distribution-center'
import { getNetworksList } from '@/api/trading-networks'
import { getRegionsList } from '@/api/regions'
import { getOptions } from '@/utils/get-options'
import Form from '@/mixins/form'
import Page from '@/mixins/page'

import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'
import RccNumberInput from 'Components/controls/rcc-number-input'
import RccConfirmDialog from 'Components/dialogs/rcc-confirm-dialog'
import RccTextInput from 'Components/controls/rcc-text-input'
import RccSelect from 'Components/controls/rcc-select'

export default {
  components: { RccTextInput, RccSelect, RccFooterButtons, RccNumberInput, RccConfirmDialog },

  mixins: [Page, Form, DistributionCenterApi],

  data() {
    return {
      regionsOptions: [],
      networksOptions: [],
      isShowConfirmDialog: false,
      valid: true,

      form: {
        id: null,
        id_portal: null,
        network_id: null,
        name: '',
        region_id: null,
        intermediate_booking_min_pallet: null,
        intermediate_booking_max_pallet: null,
        consolidation_booking_soft_limit_pallet: null
        // consolidation_booking_min_pallet: null,
        // consolidation_booking_max_pallet: null
      }
    }
  },

  computed: {
    selects() {
      return [
        { method: getNetworksList, target: 'networksOptions', errorMessage: 'Не удалось загрузить данные для Сетей' },
        { method: getRegionsList, target: 'regionsOptions', errorMessage: 'Не удалось загрузить данные для Регионов' }
      ]
    },

    isBlockedInX5() {
      return !!this.form.x5_disabled_at
    },

    warningButtonText() {
      if (this.isBlockedInX5) {
        return 'РЦ не был получен с портала Х5'
      }

      return this.form.disabled_at ? 'Разблокировать': 'Заблокировать'
    }
  },

  created() {
    this.getDc(this.$route.params.id).then(response => {
      this.form = response
    })

    getOptions.bind(this)(this.selects)
  },

  methods: {
    async handleFormSubmit() {
      await this.$refs.form.validate()

      if (!this.valid) {
        window.snackbar('Ошибки в форме', 'error')
        return
      }

      this.isSubmit = true

      this.updateDc(this.form.id, this.form)
        .then(() => {
          window.snackbar('РЦ успешно изменен', 'info')
          this.$router.push({ name: 'distribution-centers' })
        })
        .finally(() => { this.afterSubmit() })
    },

    handleBlockDcClick() {
      if (this.form.disabled_at) {
        this.dcChangeStatus()
        return
      }

      this.isShowConfirmDialog = true
    },

    dcChangeStatus() {
      this.isSubmit = true

      this.changeDcStatus(this.form.id)
        .then(() => {
          window.snackbar( `РЦ ${this.form.disabled_at ? 'разблокирован' : 'заблокирован' }`, 'info')
          this.form.disabled_at = this.form.disabled_at ? null : new Date()
        })
        .finally(() => { this.isSubmit = false })
    },

    bookingSoftLimitValidation(value) {
      return value > this.form.intermediate_booking_min_pallet
        && value < this.form.intermediate_booking_max_pallet
        || 'Должно быть больше min кол-ва паллет и меньше max кол-ва паллет'
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-distribution-center {
  &__form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__form-body {
    flex-grow: 1;
    padding: $base-form-padding;
  }

  &__booking-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__number-input {
    flex: none;
    width: 250px;
  }

  &__form-section {
    margin: 10px 0;
  }
}
</style>
